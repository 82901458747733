<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Kopya sayısı</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>

    <div>
      <div class="card-body p-0 w-50" v-if="false">
        <div class="font-weight-bold text-muted font-size-lg d-flex align-items-baseline justify-content-between">
        <span class="text-body font-weight-bolder font-size-h2 mr-2">%{{ usagePercentage }}</span>{{ this.used }} / {{ this.total }}</div>
        <div class="progress progress-xs mt-7">
          <div class="progress-bar bg-success" :class="{'bg-danger': usagePercentage === 100 }" role="progressbar" :style="`width: ${usagePercentage}%;`" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
       <div class="form-group">
        <span class="text-body font-size-h6 font-weight-bolder">Toplam: <span class="font-weight-normal">{{ this.total }} Kopya</span></span>
      </div>  
      <div class="form-group">
        <span class="text-body font-size-h6 font-weight-bolder">Kullanılan: <span class="font-weight-normal">{{ this.used }} Adet</span></span>
      </div>  
    </div>

    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
export default {
  props: ["used", "total"],
  computed: {
    usagePercentage() {
      return parseInt((100 * this.used) / this.total);
    },  
  }
}
</script>

<style>

</style>