<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Promosyon tipi</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>

    <div>
      <div class="form-group">
        <span class="text-body font-size-h6 font-weight-bolder">İndirim: <span class="font-weight-normal">{{ promotionTypeText }}</span></span>
      </div>  
      <div class="mb-15" v-if="selectedProducts.length > 0">
        <label class="font-weight-bolder text-body font-size-h6">Seçili Ürünler</label>
        <div class="d-flex align-items-center flex-wrap" v-for="(item, index) in selectedProducts" :key="index">
          <div class="d-flex flex-column flex-grow-1 mr-2">
            <span class="font-weight-normal text-body font-size-h6 mb-1">{{ item.title.tr }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>

export default {
  props: ["type", "selectedProducts"],
  computed: {
    promotionTypeText() {
      if (this.type === 1) {
        return 'Tüm Ürünlerde Geçerli'
      } else if (this.type === 2) {
        return 'Seçili Ürünlerde Geçerli'
      } else {
        return '';
      }
    }
  }
}
</script>

<style>

</style>