<template>
  <div class="card card-custom card-fit experience-content">
    <div class="card-body">
      <Discount :unit="content.discount.unit" :amount="content.discount.amount" @edit="openEditor('discount')"/>
      <CustomerType :type="content.customerType" @edit="openEditor('customerType')"/>
      <NumberOfCopy :used="content.copy.used" :total="content.copy.total" @edit="openEditor('numberOfCopy')"/>
      <ValidDates :from="content.validation.start" :to="content.validation.end"  @edit="openEditor('validDates')"/>
      <MinBasketPrice :price="content.minBasketPrice" @edit="openEditor('minBasketPrice')"/>
      <ValidCurrency :currency="content.validCurrency" @edit="openEditor('validCurrency')"/>
      <PromotionType :type="content.promotion.type" :selected-products="content.promotion.selectedProducts" @edit="openEditor('promotionType')"/>
    </div>
  </div>
</template>

<script>
import CustomerType from "@/view/pages/promotion-code/components/content/CustomerType";
import NumberOfCopy from "@/view/pages/promotion-code/components/content/NumberOfCopy";
import ValidDates from "@/view/pages/promotion-code/components/content/ValidDates";
import MinBasketPrice from "@/view/pages/promotion-code/components/content/MinBasketPrice";
import ValidCurrency from "@/view/pages/promotion-code/components/content/ValidCurrency";
import PromotionType from "@/view/pages/promotion-code/components/content/PromotionType";
import Discount from "@/view/pages/promotion-code/components/content/Discount";

export default {
  props: ["content"],
  components: {
    CustomerType,
    NumberOfCopy,
    ValidDates,
    MinBasketPrice,
    ValidCurrency,
    PromotionType,
    Discount
  },
  methods: {
    openEditor(editor) {
      this.$emit('edit', editor);
    },
  }
}
</script>

<style>

</style>