<template>
  <div class="card card-custom gutter-b">
    <div class="card-header border-0 pt-5">
      <div class="card-title font-weight-bolder">
        <div class="card-label text-dark font-weight-bolder">Kullanım Oranı 
        <div class="font-size-sm text-muted mt-2">{{ used }} Adet Kullanıldı</div></div>
      </div>
    </div>
    <div class="card-body py-0">
      <apexchart
        :options="chartOptions"
        :series="series"
        type="radialBar"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["used", "total"],
  data() {
    return {
      chartOptions: {},
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    
    series() {
      let pct = parseInt((100 * this.used) / this.total);
      return [pct];
    }
  },
  mounted() {
    this.chartOptions = {
        chart: {
          height: 250,
          type: 'radialBar',
          offsetY: 0
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,

                hollow: {
                    margin: 0,
                    size: "70%"
                },
                dataLabels: {
                    showOn: "always",
                    name: {
                        show: true,
                        fontSize: "13px",
                        fontWeight: "700",
                        offsetY: -5,
                        color: this.layoutConfig("colors.gray.gray-500")
                    },
                    value: {
                        color: this.layoutConfig("colors.gray.gray-700"),
                        fontSize: "30px",
                        fontWeight: "700",
                        offsetY: -40,
                        show: true
                    }
                },
                track: {
                    background: this.layoutConfig("colors.theme.light.success"),
                    strokeWidth: '100%'
                }
            }
        },
        colors: [this.layoutConfig("colors.theme.base.success")],
        stroke: {
            lineCap: "round",
        },
        labels: ["Kullanıldı"]
    }
  }
}
</script>

<style>

</style>