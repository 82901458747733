<template>
  <OverlayModalBase @close="closeOverlayModal"> 
    <CustomerType :code="code" @close="closeOverlayModal" v-if="selectedSection == 'customerType'"/>
    <NumberOfCopy :code="code" @close="closeOverlayModal" v-if="selectedSection == 'numberOfCopy'"/>
    <ValidDates :code="code" @close="closeOverlayModal" v-if="selectedSection == 'validDates'"/>
    <MinBasketPrice :code="code" @close="closeOverlayModal" v-if="selectedSection == 'minBasketPrice'"/>
    <Discount :code="code" @close="closeOverlayModal" v-if="selectedSection == 'discount'"/>
    <PromotionType :code="code" @close="closeOverlayModal" v-if="selectedSection == 'promotionType'"/>
    <ValidCurrency :code="code" @close="closeOverlayModal" v-if="selectedSection == 'validCurrency'"/>
  </OverlayModalBase>
</template>

<script>
import OverlayModalBase from "@/view/pages/experience/base_ui/OverlayModalBase";
import CustomerType from "@/view/pages/promotion-code/components/editor/CustomerType";
import NumberOfCopy from "@/view/pages/promotion-code/components/editor/NumberOfCopy";
import ValidDates from "@/view/pages/promotion-code/components/editor/ValidDates";
import MinBasketPrice from "@/view/pages/promotion-code/components/editor/MinBasketPrice";
import Discount from "@/view/pages/promotion-code/components/editor/Discount";
import PromotionType from "@/view/pages/promotion-code/components/editor/PromotionType";
import ValidCurrency from "@/view/pages/promotion-code/components/editor/ValidCurrency";

export default {
  props: ["code", "selectedSection"],
  components: {
    OverlayModalBase,
    CustomerType,
    NumberOfCopy,
    ValidDates,
    MinBasketPrice,
    Discount,
    PromotionType,
    ValidCurrency
  },
  methods: {
    closeOverlayModal() {
      this.$emit('close');
    }
  }
}
</script>


<style>
.option {
  cursor: pointer;
  border-width: 2px;
}
.option .option-label .option-body {
  font-size: 1.08rem !important;
  max-width: 80% !important;
}
.option.selectable.selected,
.option.selectable:hover {
  border-color: var(--dark);
  background-color: var(--light);
}
.option.selected {
  border-width: 2px;
}
</style>