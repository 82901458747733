<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Geçerli para birimi</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>

    <div>
      <div class="form-group">
        <span class="text-body font-size-h6 font-weight-bolder">Para birimi: <span class="font-weight-normal">{{ validCurrencyText }}</span></span>
      </div>  
    </div>
    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["currency"],
  computed: {
    ...mapGetters(["promotionConfig"]),

    validCurrencyText() {
      return this.promotionConfig(`currency.${this.currency}.text`);
    }
  }
}
</script>

<style>

</style>