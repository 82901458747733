<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Geçerlilik süresi</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>

    <div>
      <div class="form-group">
        <span class="text-body font-size-h6 font-weight-bolder">Başlangıç: <span class="font-weight-normal">{{ fromText }}</span></span>
      </div>  
      <div class="form-group">
        <span class="text-body font-size-h6 font-weight-bolder">Bitiş: <span class="font-weight-normal">{{ toText }}</span></span>
      </div>  
    </div>
    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: ["from", "to"],
  computed: {
    fromText() {
      return moment(this.from, 'YYYY-MM-DD').format('D MMMM YYYY');
    },
    toText() {
      return moment(this.to, 'YYYY-MM-DD').format('D MMMM YYYY');
    }
  },
  mounted() {
    moment.locale('tr');
  },
}
</script>

<style>

</style>