<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      İndirim tutarını düzenleyin
    </h1>
    <p class="font-size-h5 mb-7">
      Ne kadarlık bir indirim sunmak istediğinizi belirtin.
    </p>
    
    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group w-50">
      <div class="input-group">
        <div class="input-group-prepend">
          <b-dropdown no-flip :text="selectedDiscountType === 'PCT' ? 'Yüzde' : selectedDiscountType" class="btn btn-sm btn-secondary p-0">
            <b-dropdown-item @click="selectedDiscountType = item.id" v-for="(item, index) in discountTypes" :key="index">{{ item.text }}</b-dropdown-item>
          </b-dropdown>
        </div>
        <input type="number" class="form-control form-control-lg" v-model="discountAmount">
      </div>
    </div>

    <div class="form-group border-top mt-20 pt-10">
      <div>
        <button 
          class="btn btn-dark font-weight-bolder px-9 py-4 d-flex align-items-center" 
          :disabled="discountAmount <= 0 || discountAmount.length === 0 || isSaving"
          @click="set"
        >
        <div
          class="spinner spinner-white pr-10"
          v-if="isSaving"
        ></div>
        <span>Değişiklikleri Kaydet</span>
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: ["code"],
  data() {
    return {
      discountTypes: [
        {
          text: 'Yüzde',
          id: 'PCT'
        },
        {
          text: 'TRY',
          id: 'TRY'
        },
        {
          text: 'USD',
          id: 'USD'
        },
        {
          text: 'EUR',
          id: 'EUR'
        }
      ],
      selectedDiscountType: null,
      discountAmount: 0,
      error: {
        status: false,
        message: '',
      },
      isSaving: false,
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      ApiService.get(`promotion-code/discount/get.req.php?code=${this.code}`)
      .then(({ data }) => {
        this.selectedDiscountType = data.discountUnit;
        this.discountAmount = data.discountAmount;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    set() {
      this.isSaving = true;
      const postData = JSON.stringify({
        code: this.code,
        unit: this.selectedDiscountType,
        amount: this.discountAmount
      });

      ApiService.post('promotion-code/discount/set.req.php', postData)
       .then(() => {
        this.isSaving = false;
        this.error.status = false;
        this.$emit('close');
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },
  }
}
</script>

<style>

</style>