<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Geçerli müşteri tipini düzenleyin
    </h1>
    <p class="font-size-h5 mb-7">
      Promosyon kodunu kullanabilecek müşteri tipini seçin.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group" v-for="type in customerTypes" :key="type.text">
      <label
        class="option selectable"
        :class="{ selected: type.value == selectedCustomerType }"
      >
        <span class="option-control">
          <span class="radio radio-dark">
            <input
              type="radio"
              name="service_type_option"
              :value="type.value"
              v-model="selectedCustomerType"
            />
            <span></span>
          </span>
        </span>
        <span class="option-label">
          <span class="option-head">
            <span class="option-title">
              {{ type.name }}
            </span>
          </span>
        </span>
      </label>
    </div>

    <div class="form-group border-top mt-20 pt-10">
      <div>
        <button 
          class="btn btn-dark font-weight-bolder px-9 py-4 d-flex align-items-center" 
          :disabled="selectedCustomerType === null || isSaving"
          @click="set"
        >
        <div
          class="spinner spinner-white pr-10"
          v-if="isSaving"
        ></div>
        <span>Değişiklikleri Kaydet</span>
        </button>
      </div>
    </div>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from '@/core/services/api.service';

export default {
  props: ["code"],
  data() {
    return {
      selectedCustomerType: null,
      error: {
        status: false,
        message: '',
      },
      isSaving: false,
    }
  },
  computed: {
    ...mapGetters(["promotionConfig"]),

    customerTypes() {
      let arr = [];
      Object.entries(this.promotionConfig(`customerType`)).forEach(([key, value]) => {
        arr.push({
          value: parseInt(key),
          name: value.text
        });
      });
      return arr;
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      ApiService.get(`promotion-code/customer-type/get.req.php?code=${this.code}`)
      .then(({ data }) => {
        this.selectedCustomerType = data.customerType;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    set() {
      this.isSaving = true;
      const postData = JSON.stringify({
        code: this.code,
        customerType: this.selectedCustomerType
      });

      ApiService.post('promotion-code/customer-type/set.req.php', postData)
       .then(() => {
        this.isSaving = false;
        this.error.status = false;
        this.$emit('close');
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },
  }
}
</script>

<style>

</style>