<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">Minimum alışveriş tutarı</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>

    <div>
      <div class="form-group">
        <span class="text-body font-size-h6 font-weight-bolder">Alışveriş Tutarı: <span class="font-weight-normal">{{ price }} TRY</span></span>
        <span class="text-body font-size-h4 font-weight-bolder" v-if="false">{{ price }} TRY</span>
      </div>  
    </div>
    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>

export default {
  props: ["price"],
  computed: {
    
  }
}
</script>

<style>

</style>