<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Promosyon seçeneklerini düzenleyin
    </h1>
    <p class="font-size-h5 mb-7">
      Uygulanacak indirim koşullarını belirtin.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group" v-for="type in promotionTypes" :key="type.title">
      <label
        class="option selectable"
        :class="{ selected: type.value == selectedPromotionType }"
      >
        <span class="option-control">
          <span class="radio radio-dark">
            <input
              type="radio"
              name="service_type_option"
              :value="type.value"
              v-model="selectedPromotionType"
            />
            <span></span>
          </span>
        </span>
        <span class="option-label">
          <span class="option-head">
            <span class="option-title">
              {{ type.title }}
            </span>
          </span>
        </span>
      </label>
    </div>

    <div class="mb-3">
      <button @click="$bvModal.show('modal-product-search')" v-if="selectedPromotionType === 2" type="button" class="btn btn-hover-transparent-dark font-weight-bold d-inline-flex align-items-center">
        <span>Bir ürün ekle</span>
      </button>
    </div>

    <div class="btn btn-icon btn-light w-auto btn-clean bg-hover-light border-0 d-inline-flex align-items-center btn-lg px-2 mr-3 mb-3" v-for="(item, index) in selectedProducts" :key="index">
      <button type="button" class="btn btn-icon btn-circle btn-xs btn-white bg-hover-secondary mr-3" @click="removeSelectedProduct(index)">
        <i class="ki ki-close icon-sm text-dark"></i>
      </button>
      <div class="d-flex flex-column align-items-start justify-content-center mr-2">
        <span class="text-dark-50 font-weight-bolder font-size-sm line-height-nm">{{ item.title.tr }}</span>
      </div>
    </div>

    <b-modal id="modal-product-search" ref="modal-product-search" no-fade centered header-class="py-3" footer-class="py-3" @close="handleModalClose" @hide="handleModalClose" @ok="handleModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Ürün ekleyin</h5>
          </div>
        </div>
      </template>
      
      <div class="form-group" id="app" data-app>
        <v-autocomplete
          v-model="productSearchModal.model"
          :items="items"
          :loading="productSearchModal.isLoading"
          :search-input.sync="productSearchModal.search"
          item-text="Description"
          item-value="id"
          dense
          filled
          rounded
          placeholder="Ara..."
          return-object
        ></v-autocomplete>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="productSearchModal.model === null"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>

    <div class="form-group border-top mt-20 pt-10">
      <div>
        <button 
          class="btn btn-dark font-weight-bolder px-9 py-4 d-flex align-items-center" 
          :disabled="isSaving"
          @click="set"
        >
        <div
          class="spinner spinner-white pr-10"
          v-if="isSaving"
        ></div>
        <span>Değişiklikleri Kaydet</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: ["code"],
  data() {
    return {
      selectedPromotionType: null,
      promotionTypes: [
        {
          title: 'Tüm Ürünlerde Geçerli',
          value: 1,
        },
        {
          title: 'Seçili Ürünlerde Geçerli',
          value: 2,
        }
      ],
      productSearchModal: {
        entries: [],
        isLoading: false,
        model: null,
        search: null,
      },
      selectedProducts: [],
      error: {
        status: false,
        message: '',
      },
      isSaving: false,
    }
  },
  computed: {
    items () {
      return this.productSearchModal.entries.map(entry => {
        const Description = entry.text;

        return Object.assign({}, entry, { Description })
      })
    },
    
    selectedProductsArr() {
      let arr = [];
      this.selectedProducts.forEach(element => {
        arr.push(element.id)
      });
      return arr;
    },
  },
  watch: {
    'productSearchModal.search': function(val) {
      // Items have already been loaded
      if (this.items.length > 0) return

      // Items have already been requested
      if (this.productSearchModal.isLoading) return

      this.productSearchModal.isLoading = true

      // Lazily load input items
      ApiService.get(`experience/search.req.php?search=${val}`)
      .then(({ data }) => {
        this.productSearchModal.entries = data.listings;
        this.productSearchModal.isLoading = false;
      })
      .catch(() => {
        this.productSearchModal.entries = [];
        this.productSearchModal.isLoading = false;
      });
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      ApiService.get(`promotion-code/promotion-type/get.req.php?code=${this.code}`)
      .then(({ data }) => {
        this.selectedPromotionType = data.productType;
        this.selectedProducts = data.selectedProducts;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    set() {
      this.isSaving = true;
      const postData = JSON.stringify({
        code: this.code,
        type: this.selectedPromotionType,
        selectedProducts: this.selectedProductsArr
      });

      ApiService.post('promotion-code/promotion-type/set.req.php', postData)
       .then(() => {
        this.isSaving = false;
        this.error.status = false;
        this.$emit('close');
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    handleModalOk() {
      this.selectedProducts.push(this.productSearchModal.model);
    },
    handleModalClose() {
      this.productSearchModal.model = null;
    },
    removeSelectedProduct(index) {
      this.selectedProducts.splice(index, 1);
    },
  }
}
</script>

<style>

</style>