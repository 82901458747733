<template>
  <div class="pb-5">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Geçerlilik süresini düzenleyin
    </h1>
    <p class="font-size-h5 mb-7">
      Promosyon kodunun geçerli olduğu tarih aralığını seçin.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group w-50">
      <DateRangePicker :key="datePicker.key" :initial-date="{ start: datePicker.start, end: datePicker.end }" @change="datePickerChanged" />
    </div>

    <div class="form-group border-top mt-20 pt-10">
      <div>
        <button 
          class="btn btn-dark font-weight-bolder px-9 py-4 d-flex align-items-center" 
          :disabled="isSaving"
          @click="set"
        >
        <div
          class="spinner spinner-white pr-10"
          v-if="isSaving"
        ></div>
        <span>Değişiklikleri Kaydet</span>
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import DateRangePicker from "@/view/content/datepicker/DateRangePicker";

export default {
  props: ["code"],
  components: {
    DateRangePicker,
  },
  data() {
    return {
      datePicker: {
        key: 1,
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
      error: {
        status: false,
        message: '',
      },
      isSaving: false,
    }
  },
  mounted() {
    moment.locale('tr');
    this.get();
  },
  methods: {
    get() {
      ApiService.get(`promotion-code/valid-dates/get.req.php?code=${this.code}`)
      .then(({ data }) => {
        this.datePicker.start = data.from;
        this.datePicker.end = data.to;
        this.datePicker.key += 1;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    set() {
      this.isSaving = true;
      const postData = JSON.stringify({
        code: this.code,
        from: this.datePicker.start,
        to: this.datePicker.end
      });

      ApiService.post('promotion-code/valid-dates/set.req.php', postData)
       .then(() => {
        this.isSaving = false;
        this.error.status = false;
        this.$emit('close');
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    datePickerChanged(dateRange) {
      this.datePicker.start = dateRange.start;
      this.datePicker.end = dateRange.end;
    },
  }
}
</script>

<style>

</style>