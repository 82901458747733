<template>
  <div class="mb-10">
    <div class="d-flex align-items-center justify-content-between mb-10">
      <h4 class="text-body font-weight-bolder">İndirim tutarı</h4>
      <button class="btn btn-outline-dark font-weight-bold" @click="$emit('edit')">Düzenle</button>
    </div>

    <div>
      <div class="form-group" v-if="false">
        <span class="text-body font-size-h6 font-weight-bolder">İndirim: <span class="font-weight-normal">{{ discountText }}</span></span>
      </div>  

      <div class="form-group">
        <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 d-block">{{ discountText }}</span>
        <span class="font-weight-bold text-muted font-size-sm" v-if="false">İndirim tutarı</span>
      </div>
    </div>
    <div class="separator separator-solid separator-border-2 my-10"></div>
  </div>
</template>

<script>

export default {
  props: ["unit", "amount"],
  computed: {
    discountText() {
      if (this.unit === 'PCT') {
        return '%' + this.amount;
      } else {
        return this.amount + ' ' + this.unit;
      }
    }
  }
}
</script>

<style>

</style>