<template>
  <div>
    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>
    <div v-else>
      <div class="d-flex flex-row align-items-center justify-content-between mb-10">
        <div class="d-flex flex-column">
          <h1 class="font-weight-bolder text-dark my-2 mr-5">
            {{ code }}
          </h1>
          <div class="my-2">
            <label class="label label-inline label-outline-secondary label-lg font-size-lg text-body font-weight-bolder mr-6" v-if="content !== null">
              <span class="label label-dot label-lg mr-2" :class="`label-${promotionConfig(`status.${status}.color`)}`"></span>
              {{ promotionConfig(`status.${status}.text`) }}
            </label>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center">
          <router-link
            :to="{ name: 'promotion-codes'}"
            v-slot="{ href }"
          >
            <a :href="href" class="btn btn-light-primary btn-fixed-height font-weight-bold px-2 px-lg-5">
              <span class="d-none d-md-inline">Promosyon Kodları</span>
            </a>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <UsageRate :used="content.copy.used" :total="content.copy.total" v-if="content !== null" />

          <div class="card card-custom gutter-b" v-if="false">
            <!--begin::Body-->
            <div class="card-body">
              <a href="#" class="card-title font-weight-bold text-muted text-hover-primary font-size-h5">Son Rezervasyon</a>
              <div class="font-weight-bold text-success mt-9 mb-5">ZNF-ABSC12D</div>
              <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">Uzungöl ve Haldizen Vadisi Günübirlik Grup Turu</p>
            </div>
            <!--end::Body-->
          </div>
        </div>
        <div class="col-lg-8">
          <Content :content="content" v-if="content !== null"  @edit="openOverlay"/>
          <Overlay :code="code" :selected-section="selectedSection" @close="closeOverlayModal" v-if="selectedSection != null"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import Content from "@/view/pages/promotion-code/components/content/Content";
import Overlay from "@/view/pages/promotion-code/components/content/Overlay";
import UsageRate from "@/view/pages/promotion-code/components/UsageRate";

export default {
  components: {
    Content,
    Overlay,
    UsageRate
  },
  data() {
    return {
      code: this.$route.params.code,
      content: null,
      selectedSection: null,
      error: {
        status: false,
        message: 'Beklenmedik bir hatayla karşılaştık. Lütfen daya sonra tekrar deneyin.'
      },
    }
  },
  computed: {
    ...mapGetters(["promotionConfig"]),

    status() {
      const today = moment().format('YYYY-MM-DD');
      if (moment(today).isBefore(this.content.validation.start)) {
        return 2;
      } else if (moment(today).isSameOrAfter(this.content.validation.start) && moment(today).isSameOrBefore(this.content.validation.end)) {
        return 1;
      } else if (moment(today).isAfter(this.content.validation.end)) {
        return 3;
      } else {
        return 0;
      }
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      ApiService.get(`promotion-code/get.req.php?code=${this.code}`)
      .then(({ data }) => {
        this.content = data.details;
        this.error.status = false;
      })
      .catch(() => {
        this.error.status = true;
      });
    },

    closeOverlayModal() {
      this.selectedSection = null;
      this.get();
    },
    openOverlay(editor) {
      this.selectedSection = editor;
    }
  }
}
</script>

<style>

</style>